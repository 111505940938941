import {createBrowserRouter, Navigate} from 'react-router-dom';
import AppLayout from 'components/AppLayout'
import Dashboard from "components/dashboard";
import Home from "components/dashboard/Home/Home";
import History from "../components/dashboard/History/History";
import Setting from "../components/dashboard/Setting/Setting";
import Scan from "../components/dashboard/Charge/Scan";
import PrepareInfo from "../components/dashboard/Charge/PrepareChargingInfo";
import ChargingStatus from "../components/dashboard/Charge/ChargingStatus";
import FinishCharging from "../components/dashboard/Charge/FinishCharging";
import Profile from "../components/dashboard/Setting/UserProfile";
import Car from "../components/dashboard/Setting/CarProfile";
import Tax from "../components/dashboard/Setting/Tax";
import Price from "../components/dashboard/Setting/Price";
import PrivacyPolicy from "../components/dashboard/Setting/PrivacyPolicy";
import ContactUs from "../components/dashboard/Setting/ContactUs";
import EditCar from "../components/dashboard/Setting/EditCar";
import EditUserProfile from "../components/dashboard/Setting/EditUserProfile";
import EditTax from "../components/dashboard/Setting/EditTax";
import InformationCharging from "../components/dashboard/History/InformationCharging";
import ChargingProvider from "contexts/ChargingContext";
import axios from "axios";
export default createBrowserRouter([
        {
            id: "root", path:"/", element: <AppLayout/>,
            children: [
              {
                path:"dashboard", label: 'Dashboard', element: <Dashboard/>,
                children: [
                  {path: "home", element: <Home/>},
                  {path: "charge", element: <ChargingProvider/>,
                    children:[
                      {index: true, element: <Scan/>},
                      {path:"prepareinfo", element: <PrepareInfo/>},
                      {path:"status", element: <ChargingStatus/>},
                      {path:":finish", element: <FinishCharging/>,
                           loader : async ({ params }) => {
                                        console.log("router : Enter Information Charging");
                                        console.log("app.router : ", params);
                                        console.log("app.router : params.finish : ", params.finish);
                                        const res = await axios.get(`/api/v1/mobile-app/charging-transaction/${params.finish}`);
                                        const finishObj = res.data;
                                        console.log("router : finishObj", finishObj);
                                        return finishObj;
                           }
                      }

                    ]
                  },
                  {path: "history", children:[
                        {index: true, element: <History/>},
                        {path: ":informationcharging", element: <InformationCharging/>,
                        loader : async ({ params }) => {
                                console.log("router : Enter Information Charging");
                                console.log("app.router : ", params);
                                console.log("app.router : params.informationcharging : ", params.informationcharging);
                                const res = await axios.get(`/api/v1/mobile-app/charging-transaction/${params.informationcharging}`);
                                const transactionObj = res.data;
                                console.log("router : transactionObj", transactionObj);
                                return transactionObj;
                            }
                        }
                    ]
                  },
                  {path: "setting", children:[
                        {index: true, element: <Setting/>},
                        {path: "profile", element: <Profile/>},
                        {path: "car", element: <Car/>},
                        {path: "tax", element: <Tax/>},
                        {path: "price", element: <Price/>},
                        {path: "privacy", element: <PrivacyPolicy/>},
                        {path: "contact", element: <ContactUs/>},
                        {path: "editprofile", element: <EditUserProfile/>},
                        {path: "editcar", element: <EditCar/>},
                        {path: "edittax", element: <EditTax/>}
                    ]
                  },
                  //{path: "prepareinfo", element: <ChargingStatus/>},
                ]
              },
            ]
        },
        {index: true, element: <Navigate to="/dashboard/home" replace />},
        {path: "*", element: <Navigate to="/dashboard/home" replace />}
    ]
);