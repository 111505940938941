import React, {useContext, useRef} from 'react';
import styled, {keyframes} from "styled-components";
import {Button, Divider, Form, Input, Typography, Row} from "antd";
import {AuthContext} from "contexts/AuthContext";
import {useAuthContext} from "contexts/AuthContext";
import useScript from "../hook/useScript";
import imgCar from 'assets/images/logoUltraCharge.png'
import {useNavigate, Link} from "react-router-dom";
const Login = () => {
    const signInButtonRef = useRef();
    const {onGoogleSignIn} = useAuthContext();
    const navigator = useNavigate();
    const {login} = useAuthContext();
    const onFinish = (values) => {
        const {email, password} = values;
        login(email, password);
                   console.log("Success:", values);

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useScript("https://accounts.google.com/gsi/client", () => {
        window.google.accounts.id.initialize({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            callback: onGoogleSignIn,
        });

        window.google.accounts.id.renderButton(
          signInButtonRef.current,
          {theme: 'outline', size: 'large', width: 300, shape: 'pill'}
        );
    })
    return <Container>
        <Ocean>
            <Wave />
            <Wave />
        </Ocean>

        <LoginContainer style={{width: 'minmax(350px, 90%)'}}>
            <center><img alt="example" width="30%" src={imgCar}/></center>
            <p></p>
            <Typography.Title level={3} style={{textAlign: 'center', marginTop: 0, marginBottom: 40}}>Log in </Typography.Title>
        <FormStyled
            name="basic"
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            style={{width: 'minmax(350px, 90%)'}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!'
                    }
                ]}
            >
                <Input placeholder="Enter Email"/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder="Enter Password"/>
            </Form.Item>
            <FormAction
                wrapperCol={{
                    offset: 6,
                    span: 18,
                }}
            >
                <Button type="primary" htmlType="submit" style={{width: 120}} >
                    Sign in
                </Button>
                <Link to="/forget_password">
                <Button type="link">
                    Forget Password ?
                </Button>
                </Link>
            </FormAction>
            <Divider>OR</Divider>
            <GoogleButton ref={signInButtonRef}></GoogleButton>
            <Divider style={{color:"#000000"}}/>
            <div style={{textAlign: "center"}}>
                <Link to="/signup"><Button style={{textAlign: "right"}} type="link">
                    New user? Sign up
                </Button>
                </Link>
            </div>
         </FormStyled>
        </LoginContainer>
    </Container>;
};

export default Login;

const Container = styled.div`
    height: 100dvh;
    width: 100dvw;
    background: radial-gradient(ellipse at center, rgba(255, 254, 234, 1) 0%, rgba(255, 254, 234, 1) 35%, #B7E8EB 100%);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const LoginContainer = styled.div`
    min-height: 180px;
    background: #FFF;
    padding: 32px;
    border-radius: 48px;
    backdrop-filter: blur(7px);
    opacity: 85%;
    transition: 333ms;
    box-shadow: 30px 14px 28px rgba(0, 0, 5, .2), 0 10px 10px rgba(0, 0, 0, .2);

`;
const GoogleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Ocean = styled.div`
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
`;
const waveMoving = keyframes`
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
`;
const swell = keyframes`
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
`;

const FormStyled = styled(Form)`
  min-width: 350px;
  width: 90%;
  max-width: 350px;
  background: #FFFFFF;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
  }
`;

const Wave = styled.div`
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: ${waveMoving} 5s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
        top: -175px;
        animation: ${waveMoving} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, ${swell} 7s ease -1.25s infinite;
        opacity: 1;
    }
`;
