import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate, Link} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { AimOutlined,ApiOutlined, UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined, ArrowLeftOutlined, FacebookOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
function Tax() {
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Tax Invoice</h1></center></Col>
      </Row>
    <Container>

          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <Card style={{ width: '95%' }}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <FileTextOutlined /> No tax information found
                        </Col>
                    </Row>

                </Card>
          </Row>
          <p></p>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Link to ="/dashboard/Setting/edittax">
              <Button type="primary" style={{fontSize: 24, width: 220, height:50}}>
                  <b>Add Tax Invoice</b>
              </Button>
              </Link>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;
`

export default Tax;