import {useEffect, useState} from "react";

export default function Timer(props) {

    const [runner, setRunner] = useState(0);
    useEffect(() => {
      let interval = -1;
      if(props.time){
       const starter = Date.now();
       interval = setInterval(()=>{
        setRunner(props.time+Date.now() - starter);
       },100);
      }
      return ()=>{
        clearInterval(interval)
      }
    }, [props.time]);

	return (
		<div className="timer">
			<span className="digits">
				{("0" + Math.floor((runner / 60000) / 60 /60)).slice(-2)} :
			</span>
			<span className="digits">
				{ " " +("0" + Math.floor((runner / 60000) % 60)).slice(-2)} :
			</span>
			<span className="digits">
				{ " "+ ("0" + Math.floor((runner / 1000) % 60)).slice(-2)}
			</span>

		</div>
	);
}
