import moment from "moment-timezone";

export function extractUser(token) {
  if (!token) return null;
  const {email, firstName, lastName, middleName, exp, uid} = parseJwt(token);
  if(!email || !exp) return null;
  const expired = moment(exp * 1000);
  const u = {uid, email, firstName, lastName, middleName, expired, token};
  return expired.isBefore() ? null : u;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  if (!base64Url) return {};
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}