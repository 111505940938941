import React, {useEffect, useState} from 'react';
import TabSlider from "utils/TabSlider";
import {useOutletContext, Link, useParams} from "react-router-dom";
import styled from "styled-components";
import { Row, Col, Badge } from "antd";
import { AimOutlined,ApiOutlined } from '@ant-design/icons';
import { Avatar, Card, Tooltip, message } from 'antd';
import axios from "axios";
function History() {
  const [user, setUser] = useState({});
  const [billDataList, setBillDataList] = useState([]);
  const [direction] = useOutletContext();
  const {transactionPk} = useParams();
  const { Meta } = Card;
  const [isLoading,setLoad]=useState(true);
  useEffect(() => {
       console.log("user : Enter useEffect : init user");
       onSearchLoginUser();
    }, []);
  const onSearchLoginUser = async () => {
      console.log("User : Enter onSearchLoginUser");

      try {
            //const response = await axios.get("/api/v1/mobile-app/user-login-info");
           // axios.get("/api/v1/user/users").then((response) => {
             axios.get("/api/v1/mobile-app/user-login-info").then((response) => {
             console.log(response.data);
             setUser(response.data);
             onSearchBill(response.data.email);
          });
      } catch (e) {
           console.log(e);
      }
  };
  const onSearchBill = async (myEmail) => {
      console.log("myEmail:",myEmail)
      console.log("Bill : Enter onSearchBill");
      try {
               axios.get("/api/v1/mobile-app/billing-history/"+myEmail).then((response) => {
               console.log(response.data);
               setBillDataList(response.data);
          });
      } catch (e) {
           console.log(e);
      }

  };
const renderComponent = () =>{
        console.log("History : Enter renderComponent");
        if (billDataList.index===""){
            onSearchBill();
        }else{


        const list =  billDataList.map(billDataList =>
                        <div>
                            <Link to={''+billDataList.transactionPk} >
                            <Row style={{ alignItems: 'center', padding:'5px'}}>
                            <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                              <Card hoverable style={{ width: '100%' }} cover=<div style={{ fontSize: 30, padding:'10px'}}><b>{billDataList.startTimestampDisplay}</b></div>>
                                  <Meta
                                      avatar=<div><ApiOutlined /></div>
                                      title=<div><Row><Col span={17} ><div>{billDataList.startTimestampDisplay!==""?"Success":"Fail"}</div></Col><Col><Badge style={{width:90, fontSize: 15, height:25, padding:'2px', vh:'30px'}} count={"Information"} showZero color="#faad14" /></Col></Row></div>
                                      description=<div><AimOutlined /> Location {billDataList.transactionPk}</div>
                                  />
                              </Card>
                            </Col>
                            </Row>
                            </Link>
                        </div>
        );

        return ( <div>{list}</div> );
        }
    };
  return <TabSlider direction={direction} >
    <Container>
      <div style={{ display:'center', width:'100%', alignContent:'center'}}><center><h1>Charging History</h1></center></div>
      <div style={{ display:'center', width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
         { renderComponent() }
      </div>
    </Container>
  </TabSlider>
}

const Container = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`;

export default History;