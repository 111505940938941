import React from 'react';
import {Layout, Spin, Typography} from 'antd';
import styled from "styled-components";

const Loading = () => {
    return (
        <Container>
            <Spin size="large"/>
            <Typography.Title level={4}>Loading...</Typography.Title>
        </Container>
    );
};

export default Loading;

const Container = styled(Layout)`
    width: 100dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
`