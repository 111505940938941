import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { AimOutlined,ApiOutlined, UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
function Price() {
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Pricing Rate</h1></center></Col>
      </Row>
    <Container>
          <Row style={{ width: '95%'}}>
                <h4><DollarOutlined /> Pricing Rate by Charge type</h4>
          </Row>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '20%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <img src={imgACT2} style={{width:"60%" }}/>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Normal Charge (AC)
                        </Col>
                        <Col style={{ width: '30%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           7.5 (THB)/kWh
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '20%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <img src={imgCCST2} style={{width:"60%" }}/>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Fast Charge (CCS Type 2)
                        </Col>
                        <Col style={{ width: '30%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           8.5 (THB)/kWh
                        </Col>
                    </Row>
                </Card>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;

`;

export default Price;