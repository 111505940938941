import React, {useEffect, useState} from 'react';
import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate, useParams, useLoaderData} from "react-router-dom";
import { Row, Col, Badge, Space, Button, Select, DatePicker } from "antd";
import { Avatar, Card, Tooltip, message, Input } from 'antd';
import { ArrowLeftOutlined, EnvironmentOutlined, ThunderboltOutlined, CreditCardOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
import axios from "axios";
function InformationCharging() {
  const [user, setUser] = useState({});
  const [direction] = useOutletContext();
  const [informationDataList, setInformationDataList] = useState([]);
  const [inputExpireDate, setInputExpireDate] = useState("");
  const [inputExpiryDate, setInputExpiryDate] = useState("");
  const transactionObj = useLoaderData();
  const hours = 0;
  const minutes = 0;
  const navigate = useNavigate();

  useEffect(() => {
        console.log("user : Enter useEffect : init user");
        onSearchLoginUser();
    }, []);
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

    const onExpireDateChange = (
      value,
      dateString,
    ) => {
      console.log('onExpireDateChange : Selected Time: ', value);
      console.log('onExpireDateChange : Formatted Selected Time: ', dateString);
      setInputExpireDate(dateString);
    };
    const onSearchLoginUser = async () => {
        console.log("User : Enter onSearchLoginUser");

        try {
              //const response = await axios.get("/api/v1/mobile-app/user-login-info");
             // axios.get("/api/v1/user/users").then((response) => {
               axios.get("/api/v1/mobile-app/user-login-info").then((response) => {
               console.log(response.data);
               setUser(response.data);
            });
        } catch (e) {
             console.log(e);
        }
    };

    const onExpireDateOk = (value) => {
      //console.log('onExpireDateOk : Selected Time: ', value);
    };

    const onChangeExpiryDate = (value, dateString) => {
        //console.log(value, dateString);
        console.log('onChangeExpiryDate : Selected Time: ', value);
        console.log('onChangeExpiryDate : Formatted Selected Time: ', dateString);
        setInputExpiryDate(dateString);
    };
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Information Charging</h1></center></Col>
      </Row>
    <Container>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'left'}}>
                        <Col style={{ width: '100%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <EnvironmentOutlined /> Location
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'left'}}>
                        <Col style={{ width: '100%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <ThunderboltOutlined /> Finish Charged {transactionObj?.transactionStop.stopTimestampDisplay}
                        </Col>
                    </Row>
                </Card>
          </Row>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <p style={{fontSize: 16}}>Charge duration:</p>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'right', justifyContent: 'right',alignContent: 'right' }}>
                           <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>
                                {transactionObj?.transactionStop.chargeTimeDisplay}
                           </p>
                        </Col>
                    </Row>
<Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <p style={{fontSize: 16}}>Charging Units</p>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'right', justifyContent: 'right',alignContent: 'right' }}>
                           <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{((transactionObj?.transactionStop.stopValue - transactionObj?.transactionStart.startValue)/1000).toFixed(2)} kWh</p>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <p style={{fontSize: 16}}>Price/Unit</p>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'right', justifyContent: 'right',alignContent: 'right' }}>
                           <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>7.5 THB</p>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'left'}}>
                        <Col style={{ width: '50%', alignItems: 'left', justifyContent: 'left',alignContent: 'left' }}>
                           <p style={{fontSize: 16}}>Total amount</p>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'right', justifyContent: 'right',alignContent: 'right' }}>
                           <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{(((transactionObj?.transactionStop.stopValue - transactionObj?.transactionStart.startValue)/1000)*7.5).toFixed(2)} THB</p>
                        </Col>
                    </Row>
                </Card>
          </Row>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                      <Row>
                            <Col style={{fontSize: 15, padding:'5px'}}>
                            <h3><CreditCardOutlined /> Payment Method</h3>
                            </Col>
                      </Row>
                      <Row style={{ alignItems: 'center', padding:'5px'}}>
                          <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                                <Card style={{ width: '100%' }}>
                                    <Row style={{ alignItems: 'center'}}>
                                        <Col>
                                            <p>

                                            </p>
                                        </Col>
                                        <Col span={'18'}>
                                            <p>
                                            <h4>No. **** **** **** 1234</h4>
                                            expire date:
                                            </p>
                                        </Col>
                                        <Col >
                                            <p style={{fontSize: 20, padding:'2px'}}><Badge style={{width:70, fontSize: 15, height:25, padding:'2px', vh:'30px'}} count={"Default"} showZero color="#faad14" /></p>

                                        </Col>
                                    </Row>
                                </Card>
                          </Col>
                      </Row>
                </Card>
          </Row>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Button type="primary" style={{fontSize: 24, width: 300, height:50}} onClick={() => navigate(-1)}>
                  <b>Download Tax Invoice</b>
              </Button>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;

`;

export default InformationCharging;