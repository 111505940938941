import styled from "styled-components";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {Button} from "antd";
import {HistoryOutlined, HomeOutlined, SettingOutlined, ThunderboltOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import axios from "axios";

const TABS = [
  {label: "Home",    to: "/dashboard/home", icon: <HomeOutlined style={{fontSize:21}}/>},
  {label: "Charge",    to: "/dashboard/charge", icon: <ThunderboltOutlined style={{fontSize:21}}/>},
  {label: "History", to: "/dashboard/history", icon: <HistoryOutlined style={{fontSize:21}}/>},
  {label: "Setting", to: "/dashboard/setting", icon: <SettingOutlined style={{fontSize:21}}/>},
];
function Dashboard(){
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation()  ;
  const currentTab = TABS.findIndex(({to}) => location.pathname.includes(to)) +1;
  const [user, setUser] = useState();
  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return <DashboardContainer>
    <Content>
      <Outlet context={[activeTab > currentTab ? -1 : 1]}/>
    </Content>
    <TabPanel style={{"--activeTab": currentTab}}>
      <TabBG />
      {TABS.map(({label,to,icon}, index) => (
        <NavButton key={label} to={to}  >
          <Button size="large" type="link" icon={icon} disabled={currentTab===index+1}/>
          <span className="label">{label}</span>
        </NavButton>
      ))}
      <Indicater className="shadow"/>
      <Indicater />
    </TabPanel>

  </DashboardContainer>
}
const DashboardContainer = styled.div`
    height: calc(100dvh - 72px);
    width: 100dvw;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: #FFF;
`;
const Content = styled.div`
    height: calc(100% - 72px);
    overflow: hidden auto;
    z-index: 1;
`;

const Indicater = styled.div`
    position: absolute;
    top: -29px;
    width: 56px;
    left: 0.002vw;
    height: 56px;
    background: ${(props) => props.theme.antd.colorPrimary};
    border-radius: 50%;
    border: 5px solid #FFF;
    transition: all 270ms;
    z-index: 901;  
    transform: translateX(calc((var(--activeTab) * 100vw - 50vw) / ${TABS.length} - 50% )
    );
    
    &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: -18px;
        width: 16px;
        height: 20px;
        background: transparent;
        border-bottom-right-radius: 20px;
        box-shadow: 0 10px 0 0 #FFF;
    }
    &::after {
        content: '';
        position: absolute;
        top: 4px;
        right: -17px;
        width: 16px;
        height: 20px;
        background: transparent;
        border-bottom-left-radius: 20px;
        box-shadow: 0 10px 0 0 #FFF;
    }
    
    &.shadow {
        box-shadow: 0 -6px 16px #00000033;
        z-index:899;
    }
`;
const NavButton = styled(NavLink)`
  transition: all 270ms;
  display: flex;
  flex-direction: column;
  
  & span.label {
      transition: all 270ms;
      font-size: 0;
      color: ${(props) => props.theme.antd.colorPrimary};
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: -24px;
  }
  &.active {
    transform: scale(1.2) translateY(-25px);
    & button {
        color: #FFF;
    }
    & span.label {
        font-size: 12px;
    }
  }
`;
const TabPanel = styled.div`
    height: 72px;
    box-shadow: 0 -6px 16px #00000033;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 900;
    position: relative;
    
    & a {
        z-index: 905;
    } 
`;
const TabBG = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 900;
`
export default Dashboard;