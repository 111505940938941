import React, {useEffect, useState} from 'react';
import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate, Link} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import {  ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgCar from 'assets/images/car.png'
import axios from "axios";
function UserProfile() {
  const [user, setUser] = useState({});
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  useEffect(() => {
        console.log("user : Enter useEffect : init user");
        onSearchLoginUser();
    }, []);
  const onSearchLoginUser = async () => {
      console.log("User : Enter onSearchLoginUser");

      try {
            //const response = await axios.get("/api/v1/mobile-app/user-login-info");
           // axios.get("/api/v1/user/users").then((response) => {
             axios.get("/api/v1/mobile-app/user-login-info").then((response) => {
             console.log(response.data);
             setUser(response.data);
          });
      } catch (e) {
           console.log(e);
      }
  };
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>User Profile</h1></center></Col>
      </Row>
    <Container>
          <Row style={{ width:'100%', alignItems: 'center', padding:'5px'}}>
          <Col style={{ width:'100%', alignItems: 'center', padding:'5px'}}>
                <Card style={{ width: '100%' }}>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            Firstname
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{user.firstName}</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            Lastname
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{user.lastName}</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            Gender
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{user.gender}</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            Birthdate
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}></p>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            Mobile Phone
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>088-655-5477</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col style={{ width:'30%'}}>
                            <p style={{fontSize: 16}}>
                            E-mail
                            </p>
                        </Col>
                        <Col style={{ width:'70%'}}>
                            <p style={{fontSize: 16, textAlign:'right', padding:'2px', justifyContent:'right',alignContent: 'right'}}>{user.email}</p>
                        </Col>
                    </Row>
                </Card>
          </Col>
          </Row>
          <p></p>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Link to ="/dashboard/Setting/editprofile">
              <Button type="primary" style={{fontSize: 24, width: 200, height:50}}>
                  <b>Edit Profile</b>
              </Button>
              </Link>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;
`

export default UserProfile;