import React from 'react';
import {Outlet} from "react-router-dom";
import {Layout} from "antd";
import LoginBg from 'assets/images/login-bg.jpg';
import styled from 'styled-components'

function AuthLayout(props) {
    return (
        <AuthContainer>
            <HeadingLogin>EV Charger Station Management System</HeadingLogin>
            <AuthWraper>
                <Outlet/>
            </AuthWraper>
        </AuthContainer>
    );
}

export default AuthLayout;

const AuthContainer = styled(Layout)`
  background-image: url("${LoginBg}");
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
`
const HeadingLogin = styled.div`
  width: 100%;
  text-align: center;
  font-size: 40px;
  color: #FFFFFF;
  justify-content: center;
  align-content: center;
  background: #000000AF;
  padding: 40px 32px;
  font-weight: bold;
  backdrop-filter: blur(7px);
`
const AuthWraper = styled.div`
  max-width: 100%;
  margin: auto;
`