import axios from "axios";
import {extractUser} from "./utils/AuthUtils";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("_t");

    if (token) {
        const user = extractUser(token);
        if (user) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return config;
});