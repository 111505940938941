const themeConfig = {
    token: {
        colorPrimary: "#A2E26D",
        colorInfo: "#A2E26D",
        colorSuccess: "#7F2A3C",
    },
    components: {
        Menu: {
            itemBorderRadius: 0
        }
    }
}

export default themeConfig;