import {ThemeProvider} from "styled-components";
import {theme} from "antd";
import React from "react";

const JYTMTheme = ({ children }) => {
  const { token } = theme.useToken();
  return (
    <ThemeProvider theme={{ antd: token, base: { color: "mediumseagreen" } }}>
      {children}
    </ThemeProvider>
  );
};

export default JYTMTheme