import axios from "axios";

export async function sightWithGoogle(credential){
  return await axios.post('/api/v1/auth/credential/google', {idToken: credential});
}

export async function signOut(){
  return await axios.post('/api/v1/auth/signout');
}

