import {App as AntApp, ConfigProvider} from 'antd';
import {RouterProvider} from "react-router-dom";
import ThemeProvider from "./ThemeProvider";
import themeConfig from 'theme-config'
import AuthProvider, {useAuthContext} from './contexts/AuthContext'
import ActiveChargingProvider  from './contexts/ActiveChargingContext'
import Loading from "./Loading";
import {AnimatePresence} from "framer-motion";


function App() {
  return (
        <ConfigProvider theme={themeConfig}>
          <AntApp>
            <ThemeProvider>
              <AuthProvider>
                <ActiveChargingProvider>
                  <AnimatePresence>
                    <AppRouter/>
                  </AnimatePresence>
                </ActiveChargingProvider>
              </AuthProvider>
            </ThemeProvider>
          </AntApp>
      </ConfigProvider>
  );
}


function AppRouter() {
  const {router, isReady} = useAuthContext();
  if (!isReady) return <Loading/>;
  return <RouterProvider router={router}/>;
}

export default App;
