import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message, Select } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
function EditCar() {
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Edit Car</h1></center></Col>
      </Row>
    <Container>

          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Brand*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                        <Select defaultValue="Volvo" style={{ width: '100%', }}
                              onChange={handleChange}
                              options={[
                                {
                                  value: 'Volvo',
                                  label: 'Volvo',
                                },
                                {
                                  value: 'BYD',
                                  label: 'BYD',
                                },
                                {
                                  value: 'MG',
                                  label: 'MG',
                                },
                                {
                                  value: 'BMW',
                                  label: 'BMW',
                                },
                              ]}
                        />
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Model*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                        <Select defaultValue="C40" style={{ width: '100%', }}
                              onChange={handleChange}
                              options={[
                                {
                                  value: 'C40',
                                  label: 'C40',
                                },
                                {
                                  value: 'XC40',
                                  label: 'XC40',
                                },
                                {
                                  value: 'EX30',
                                  label: 'EX30',
                                },
                              ]}
                        />
                        </Col>
                    </Row>
                </Card>
          </Row>
          <p></p>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Button type="primary" style={{fontSize: 24, width: 250, height:50}} onClick={() => navigate(-1)}>
                  <b>Save</b>
              </Button>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;

`;

export default EditCar;