import {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams, useLocation} from "react-router-dom";
import TabSlider from "../../../utils/TabSlider";
import styled from "styled-components";
import {Spin, Row, Card, Col, Badge, Button} from "antd";
import { Liquid } from '@ant-design/plots';
import imgVGL from 'assets/images/vertical_green_line.png'
import imgBatCharge from 'assets/images/battery_charging.gif'
import Timer from "../Charge/Timer";
import ControlButtons from "../Charge/ControlButtons";
import axios from "axios";
import {useChargingContext} from "contexts/ChargingContext";

function ChargingStatus(){
  const navigate = useNavigate();
  const [chargeBoxDataList, setChargeBoxDataList] = useState([]);
  let location = useLocation();
  const [direction] = useOutletContext();
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState();
  const [time, setTime] = useState(0);
  const [transactionPk, setTransactionPk] = useState();
  const {remoteStop} = useChargingContext();
    useEffect(() => {
        onSearchAll();
        const interval = setInterval(onSearchAll, 5000); //Implementing the setInterval method >> 5 Sec.
        return () => clearInterval(interval); //Clearing the interval
    }, []);

    const onSearchAll = async () => {
        console.log("OCPP16_2 : Charging");
        try {
            const response = await axios.get("/api/v1/mobile-app/current-status").then((response) => {
            setData(response.data);
            console.log(response.data);
            setTransactionPk(response.data.transactionPk);
                    onSearchTransactionPk(response.data.transactionPk);
            });

        } catch (e) {
             console.log(e);
        }
    };
    const onSearchTransactionPk = async (transactionPk) => {
             console.log("On search TransactionPk : ", transactionPk);
          try {
              const res = axios.get("/api/v1/mobile-app/charging-transaction/"+transactionPk);
              setTransactionPk(res.data);
          } catch (e) {
               console.log(e);
          }
    };
    const handleReset = () => {
        setIsActive(false);
        setTime(0);
    };
  return (
    <TabSlider direction={direction}>
      <Container>

          <Row style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center', height:80}}>
              <Content style={{fontSize: 28, }}><b>Charging Status</b></Content>

          </Row>

          <Row>
               <center><img src={imgBatCharge} style={{width:"40%" }}/></center>
          </Row>
          <Row style={{ fontSize:22, alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                            {/*<center><b> Battery Status </b></center> */}
          </Row>
          <Row style={{ fontSize:24, alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>

          </Row>
          <Row style={{ fontSize:28, alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
        <div className="stop-watch">
            <b><Timer time={data?.currentTimestamp-data?.startTimestamp} /></b>
        </div>
          </Row>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <Card style={{ width: '95%' }}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <center><p>
                            <h3>Electric Power</h3>
                            <h1> {((data?.energyActiveImportRegisterValue-data?.chargeStartValue)/1000).toFixed(2)} </h1>
                            <h3> kWh </h3>
                            </p></center>
                        </Col>
                        <Col>
                            <p >
                            <img src={imgVGL}/></p>
                        </Col>
                        <Col style={{ width: '45%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <center><p>
                            <h3>Charge Power</h3>
                            <h1> {(data?.powerActiveImportValue/1000).toFixed(2)} </h1>
                            <h3> Kw </h3>
                            </p></center>
                        </Col>
                    </Row>
                </Card>
          </Row>
          <p/>
                    <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                          <Button type="primary" style={{fontSize: 24, width: 200, height:50}} onClick={() => remoteStop() }>
                              <b>Stop Charge</b>
                          </Button>
                    </Row>
                    <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                            <p></p>
                    </Row>

      </Container>



    </TabSlider>
  );
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Content = styled.div`
    padding-top: 10px;

`;

export default ChargingStatus;