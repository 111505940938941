import TabSlider from "utils/TabSlider";
import {useEffect, useState} from "react";
import {useOutletContext, useNavigate, useParams, useLoaderData} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { AimOutlined,ApiOutlined, UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined, ArrowLeftOutlined, FacebookOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
import axios from "axios";
function FinishCharging() {
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const finishObj = useLoaderData();
  console.log("On search TransactionPk : ", finishObj);

  useEffect(() => {
            console.log("Transaction information: ", finishObj)
    }, []);
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Finish Charging</h1></center></Col>
      </Row>
    <Container>

          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <Card style={{ width: '95%' }}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '20%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <img src={imgACT2} style={{width:"60%" }}/>
                        </Col>
                        <Col style={{ width: '80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           AC Type 2 Connector 1
                        </Col>

                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center', height: 25 }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Charge date</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>01-02-2024</div>
                        </Col>
                    </Row>

                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Start charging</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>22:34:32</div>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center', height: 25 }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Stop charging</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>23:46:52</div>
                        </Col>
                    </Row>
                   <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                             <div style={{fontSize: 14, padding:'2px', height: 25 }}>Charge duration</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>01:12:20</div>
                        </Col>
                    </Row>
                   <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                             <div style={{fontSize: 14, padding:'2px', height: 25 }}>Number of units </div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>8.3 kWh</div>
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Payment method </div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                             <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}> VISA </div>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}> Transaction date </div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                             <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}> 01-02-2024 | 23:46:52 </div>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Total amount before VAT</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25 }}>233.43 THB</div>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Vat 7%</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>17.57 THB</div>
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <div style={{fontSize: 14, padding:'2px', height: 25 }}>Grand total</div>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                            <b><div style={{fontSize: 14, textAlign:'right',  justifyContent:'right',alignContent: 'right', height: 25}}>251 THB</div></b>
                        </Col>
                    </Row>
                </Card>
          </Row>
          <p></p>

          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Button type="primary" style={{fontSize: 24, width: 200, height:50}} onClick={() => navigate("/dashboard/home")}>
                  <b>Done</b>
              </Button>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;
`

export default FinishCharging;