import React, {useState} from 'react';
import styled, {keyframes} from "styled-components";
import {App, Button, Divider, Form, Input, Typography, Row, Col} from "antd";
import {useNavigate} from 'react-router-dom';
import axios from "axios";

const Register = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isSubmiting, setSubmitting] = useState();
    const {notification, modal} = App.useApp();
    const gotoLogin = () => {
        navigate("..", {replace: true, relative: "path"})
    }
    const onFinish = async (values) => {
        try {
            setSubmitting(true);
            await axios.post("/api/v1/auth/register", values);
            form.resetFields();
            modal.success({
                title: 'Sign up successfully',
                content: 'your username is ready, going to login page',
                onOk: gotoLogin
            })
        } catch (e) {
            notification.error({
                message: "Sign up Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        } finally {
            setSubmitting(false);
        }


    };

    return (
        <FormStyled
            name="basic"
            form={form}
            disabled={isSubmiting}
            labelCol={{span: 6}}
            wrapperCol={{span: 18}}
            style={{width: 'minmax(300px, 90%)'}}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Typography.Title style={{textAlign: 'center', marginTop: 0, marginBottom: 40}}>Sign up new
                user</Typography.Title>
            <Form.Item
                label="Email"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!'
                    }
                ]}
            >
                <Input placeholder="Enter Email"/>
            </Form.Item>
            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password placeholder="Enter Password"/>
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                    {
                        required: true,
                        message: 'Please input confirm password!',
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The new password that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Enter Confirm Password"/>
            </Form.Item>
            <Divider>Personal Information</Divider>
            <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                    {
                        required: true,
                        message: 'First name is required.',
                    },
                ]}
            >
                <Input placeholder="Enter First Name"/>
            </Form.Item>
            <Form.Item
                label="Middle Name"
                name="middlename"
            >
                <Input placeholder="Enter Middle Name"/>
            </Form.Item>
            <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                    {
                        required: true,
                        message: 'Last name is required.',
                    },
                ]}
            >
                <Input placeholder="Enter Last Name"/>
            </Form.Item>
            <FormAction

            >
            <Row>
                <Col>
                <Button type="primary" htmlType="submit" style={{width: 120}} loading={isSubmiting}>
                    Sign up
                </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                <Button type="link" onClick={gotoLogin}>
                    Have an account, go to login.
                </Button>
                </Col>
            </Row>
            </FormAction>
        </FormStyled>
    );
};

export default Register;

const FormStyled = styled(Form)`
  min-width: 430px;
  width: 100%;
  max-width: 430px;
  background: #FFFFFF;
  padding: 32px;
  border-radius: 24px;
  backdrop-filter: blur(7px);

`;
const FormAction = styled(Form.Item)`
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
      }
`;
const GoogleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Ocean = styled.div`
    height: 5%;
    width:100%;
    position:absolute;
    bottom:0;
    left:0;
    background: #015871;
`;
const waveMoving = keyframes`
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
`;
const swell = keyframes`
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
`;
const Wave = styled.div`
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: ${waveMoving} 5s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);

    &:nth-of-type(2) {
        top: -175px;
        animation: ${waveMoving} 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, ${swell} 7s ease -1.25s infinite;
        opacity: 1;
    }
`;