import {createContext, useContext, useEffect, useState} from "react";
import {Outlet, useNavigate, useOutletContext} from "react-router-dom";
import axios from "axios";
import {useAuthContext} from "./AuthContext";
import {Spin, message} from "antd";
import {LoadingOutlined} from "@ant-design/icons";


const ChargingContext = createContext();
const ChargingProvider = () => {
  const navigate = useNavigate();
  const {user} = useAuthContext();
  const parentOutletContext = useOutletContext();

  const [ chargeBox, setChargeBox] = useState();
  const [init, setInit] = useState(false) ;
  const [ connectorId, setConnectorId] = useState();

  useEffect(() => {
    (async()=>{
       const res = await axios.get("/api/v1/mobile-app/current-status");
       const {chargeBoxId, connectorId} = res.data;
       console.log(res.data);
       if(!chargeBoxId&&!connectorId){
         navigate("/dashboard/charge")
       }else {
         await fetchChargingBoxInfo(chargeBoxId, connectorId)
         navigate("/dashboard/charge/status")
       }
       setInit(true)
    })()
  }, []);

  async function fetchChargingBoxInfo(chargeBoxId, connId) {
    const res = await axios.get(`/api/v1/chargebox/chargeboxs/${chargeBoxId}`);
    const chargeBoxInfo = res.data;
    setChargeBox(chargeBoxInfo)
    setConnectorId(connId)
    return chargeBoxInfo;
  }

  const setQrCode = async (qrCode) =>{

    if(typeof qrCode === 'string'){
      const [chargeBoxId, connId] = qrCode.split(",");
      const chargeBoxInfo = await fetchChargingBoxInfo(chargeBoxId, connId);
      const connectorStatus = chargeBoxInfo.connectors.find(c=>c.connectorId===Number(connId));
      if(["Preparing", "Available"].includes(connectorStatus?.status)){
        navigate("/dashboard/charge/prepareinfo");
        return false;
      }else{
        message.error("Connector Unavailable");
        navigate("/dashboard/charge");
        return false;

      }
    }

  }

  const remoteStart = async () => {
    const {chargeBoxId} = chargeBox;
    const {email} = user;
    console.log(`Mobile remote start : Enter remoteStart(email=${email}, chargeBoxId=${chargeBoxId}, connectorId=${connectorId})`);
    //const email = "user@gmail.com"; //TODO
    const res = await axios.post("/api/v1/mobile-app/start-charge",  { chargeBoxId, connectorId, idTag: email, email });

    if(res.data!=null){
      console.log("Success");
      navigate("/dashboard/charge/status");
      return "Mobile start-charge : Success";
    }else{
      throw new Error("Mobile start-charge : "+ res?.data?.message);
    }
  }

 const remoteStop = async () => {
    const {chargeBoxId} = chargeBox;
    const {email} = user;
    console.log(`Mobile remote stop : Enter remoteStart(email=${email}, chargeBoxId=${chargeBoxId}, connectorId=${connectorId})`);
    //const email = "user@gmail.com"; //TODO
    const res = await axios.post("/api/v1/mobile-app/stop-charge",  { chargeBoxId, connectorId });
    const res1 = await axios.get("/api/v1/mobile-app/current-status");
    if(res.data!=null){
      console.log("Success");
      console.log(res1);
      navigate("/dashboard/charge/finish");
      return "Mobile stop-charge : Success";
    }else{
      throw new Error("Mobile stop-charge : "+ res?.data?.message);
    }
  }



  return (
    <ChargingContext.Provider
      value={{ chargeBox , setQrCode, remoteStart, remoteStop}}>
      {!init&&<div style={{height:'100%'}}>
              <Spin style={{position: "absolute",
                            top: "50vh",
                            left: "50vw",
                            transform: "translate(-50%, -50%)"}}
                     indicator={<LoadingOutlined style={{ fontSize: 80 }} spin />} />
      </div>}
      {init&&<Outlet context={parentOutletContext}/>}
    </ChargingContext.Provider>
  )
}

export default ChargingProvider;

export const useChargingContext = () => useContext(ChargingContext);