import React, {useEffect, useState} from 'react';
import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate} from "react-router-dom";
import { Row, Col, Badge, Space, Button, Select, DatePicker } from "antd";
import { Avatar, Card, Tooltip, message, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
import axios from "axios";
function EditUserProfile() {
  const [user, setUser] = useState({});
  const [direction] = useOutletContext();
  const [inputExpireDate, setInputExpireDate] = useState("");
  const [inputExpiryDate, setInputExpiryDate] = useState("");
  const navigate = useNavigate();
  useEffect(() => {

        console.log("user : Enter useEffect : init user");
        onSearchLoginUser();
    }, []);
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
    const onExpireDateChange = (
      value,
      dateString,
    ) => {
      console.log('onExpireDateChange : Selected Time: ', value);
      console.log('onExpireDateChange : Formatted Selected Time: ', dateString);
      setInputExpireDate(dateString);
    };
    const onSearchLoginUser = async () => {
        console.log("User : Enter onSearchLoginUser");

        try {
              //const response = await axios.get("/api/v1/mobile-app/user-login-info");
             // axios.get("/api/v1/user/users").then((response) => {
               axios.get("/api/v1/mobile-app/user-login-info").then((response) => {
               console.log(response.data);
               setUser(response.data);
            });
        } catch (e) {
             console.log(e);
        }
    };

    const onExpireDateOk = (value) => {
      //console.log('onExpireDateOk : Selected Time: ', value);
    };

    const onChangeExpiryDate = (value, dateString) => {
        //console.log(value, dateString);
        console.log('onChangeExpiryDate : Selected Time: ', value);
        console.log('onChangeExpiryDate : Formatted Selected Time: ', dateString);
        setInputExpiryDate(dateString);
    };
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Edit User Profile</h1></center></Col>
      </Row>
    <Container>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Firstname*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Firstname" value={user.firstName} />
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Lastname*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Lastname" value={user.lastName} />
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '49%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Row style={{ alignItems: 'center'}}>
                           Birthdate
                           </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <DatePicker style={{ width: '95%'}} onChange={onChangeExpiryDate}  />
                           </Row>
                        </Col>
                        <Col style={{ width: '2%'}}>
                        </Col>
                        <Col style={{ width: '49%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Row style={{ alignItems: 'center'}}>
                           Gender
                           </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <Select value={user.gender} style={{ width: '100%', }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'Male',
                                          label: 'Male',
                                        },
                                        {
                                          value: 'Female',
                                          label: 'Female',
                                        },
                                        {
                                          value: 'Other',
                                          label: 'Other',
                                        },
                                      ]}
                                />
                           </Row>
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        E-mail
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Input placeholder="E-mail address" value={user.email} disabled />
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Mobile Number
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                        <Input placeholder="Mobile Number" />
                        </Col>
                    </Row>
                </Card>
          </Row>
          <p></p>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Button type="primary" style={{fontSize: 24, width: 250, height:50}} onClick={() => navigate(-1)}>
                  <b>Save</b>
              </Button>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;

`;

export default EditUserProfile;