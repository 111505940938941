import {createContext, useContext, useEffect, useState} from "react";
import {notification} from "antd";
import appRouter from 'routers/app.router'
import authRouter from 'routers/auth.router'
import {extractUser} from "utils/AuthUtils";
import {sightWithGoogle, signOut} from "actions/AuthenticationAction";
import axios from "axios";
const AuthContext = createContext();


const AuthProvider = ({children}) => {

    const [user, setUser] = useState();
    const [isReady, setReady] = useState();

    useEffect(() => {
        const token = localStorage.getItem("_t");
        if (token) {
            const tempUser = extractUser(token);
            if (tempUser) {
                setUser(tempUser)
            }
        }
        setReady(true)
    }, []);
    const login = async (email, password) => {
        try {
            const {data, status} = await axios.post('/api/v1/auth/authenticate', {email, password});
            if (status === 200) {
                const {token} = data;
                const tempUser = extractUser(token);
                if (tempUser) {
                    setUser(tempUser)
                }
                localStorage.setItem("_t", token);
            }
        } catch (e) {
            notification.error({
                message: "Login Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        }
    }
    const onGoogleSignIn = async res => {
        try {
            const {credential} = res;
            const {data, status} = await sightWithGoogle(credential);
            if (status === 200) {

                const {token, displayName, pictureUrl} = data;
                const tempUser = extractUser(token);
                if (tempUser) {
                    setUser(tempUser)
                }
                localStorage.setItem("_t", token);
                localStorage.setItem("_d", displayName);
                localStorage.setItem("_p", pictureUrl);
            }
        } catch (e) {
            notification.error({
                message: "Login Error",
                description: e.response?.data?.message || "Something went wrong, please try again later"
            })
        }
    }

    const logout = async () => {
        setUser(null);
        await signOut();
        localStorage.removeItem("_t");
        localStorage.removeItem("_d");
        localStorage.removeItem("_p");
    }

    return (
        <AuthContext.Provider
            value={{ login, logout, onGoogleSignIn, isReady, user, router: user ? appRouter : authRouter}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;

export function useAuthContext() {
    return useContext(AuthContext)
}