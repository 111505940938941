import {useOutletContext} from "react-router-dom";
import TabSlider from "../../../utils/TabSlider";
import styled from "styled-components";
import {Badge, Button, Card, Col, message, Row} from "antd";
import imgACT2 from 'assets/images/AC_Type2.png'
import {useChargingContext} from "contexts/ChargingContext";

function PrepareChargingInfo(){
  const [direction] = useOutletContext();

  const {remoteStart, chargeBox} = useChargingContext();
  console.log(chargeBox);

  const handleClickStart = async () => {
    try {
      const msg = await remoteStart()
      message.success(msg);
    } catch (e) {
      console.log(e);
      message.error(e.message);
    }
  };
  return (
    <TabSlider direction={direction}>
      <Container>

          <Row style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center', height:80}}>
              <Content style={{fontSize: 28, }}><b>Preparing Charge</b></Content>

          </Row>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                <Card style={{ width: '95%' }}>
                    <Row style={{ alignItems: 'center'}}>
                            Please connect charge cable to your car.
                    </Row>
                </Card>
          </Row>
          <p/>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                <Card style={{ width: '100%' }}>
                    <Row>
                        <Col>Location: </Col>
                    </Row>
                    <p/>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '20%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <img src={imgACT2} style={{width:"60%" }}/>
                        </Col>
                        <Col style={{ width: '50%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Normal Charge (AC)
                        </Col>
                        <Col style={{ width: '30%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           7.5 (THB)/kWh
                        </Col>
                    </Row>
                    <p/>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '20%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                        </Col>
                        <Col style={{ width: '80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <p style={{fontSize: 14, padding:'2px'}}><b>Status:</b> <Badge style={{width:70, fontSize: 14, height:25, padding:'2px', vh:'30px'}} count={"Available"} showZero color="#faad14" /></p>
                        </Col>
                    </Row>
                </Card>
          </Row>
          <p/>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                <Card style={{ width: '100%' }}>
                    <Row style={{ alignItems: 'center'}}>
                            <h3>Payment Method.</h3>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col>
                            <p>
                            </p>
                        </Col>
                        <Col span={'18'}>
                            <h4>No. **** **** **** 1234</h4>
                        </Col>
                        <Col >
                            <p style={{fontSize: 20, padding:'2px'}}><Badge style={{width:70, fontSize: 15, height:25, padding:'2px', vh:'30px'}} count={"Default"} showZero color="#faad14" /></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>*Advance booking amount 600 THB</Col>
                    </Row>
                </Card>
          </Row>
          <p/>
                    <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                          <Button type="primary" style={{fontSize: 24, width: 200, height:50}} onClick={handleClickStart}>
                              <b>Start Charge</b>
                          </Button>
                    </Row>
                    <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                            <p></p>
                    </Row>

      </Container>



    </TabSlider>
  );
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Content = styled.div`
    padding-top: 10px;

`;

export default PrepareChargingInfo;