import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate} from "react-router-dom";
import { Row, Col, Badge, Space, Button } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { AimOutlined,ApiOutlined, UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";

function PrivacyPolicy() {
  const [direction] = useOutletContext();
  const navigate = useNavigate();
  return <TabSlider direction={direction} >


      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Privacy Policy</h1></center></Col>
      </Row>
    <Container>
      <p>Last Updated: January 21, 2024</p>

      <h2>Introduction</h2>

      <p>Welcome to [TechRevth]! This Privacy Policy outlines how we collect, use, disclose, and protect your
        information when you use our website and web app services.</p>

      <p>By accessing or using [TechRevth.com] and its associated web app ([TechRevth Web App]), you agree to the
        terms outlined in this Privacy Policy.</p>

      <h2>Information We Collect</h2>

      <h3>Personal Information</h3>

      <p>When you use our web app, we may collect personally identifiable information, such as:</p>

      <ul>
        <li>Full name</li>
        <li>Email address</li>
        <li>Location data</li>
        <li>Electric Vehicle (EV) charger preferences</li>
        <li>Usage data related to the web app</li>
      </ul>

      <h3>Non-Personal Information</h3>

      <p>We may also collect non-personal information, such as:</p>

      <ul>
        <li>Browser type</li>
        <li>Device type</li>
        <li>IP address</li>
        <li>Anonymous usage statistics</li>
      </ul>

      <h2>How We Use Your Information</h2>

      <p>We use the collected information for various purposes, including:</p>

      <ul>
        <li>Providing and maintaining the web app</li>
        <li>Improving user experience</li>
        <li>Personalizing content and features</li>
        <li>Sending updates and notifications</li>
        <li>Analyzing usage patterns</li>
      </ul>


      <h2>Contact Us</h2>

      <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at <a
        href="mailto:jytm.group@gmail.com">jytm.group@gmail.com</a>.</p>
    </Container>
    </TabSlider>
}

const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;
`
export default PrivacyPolicy;