import TabSlider from "utils/TabSlider";
import {useOutletContext, useNavigate} from "react-router-dom";
import {useState} from 'react';
import { Row, Col, Badge, Space, Button, Select, DatePicker, Flex } from "antd";
import { Avatar, Card, Tooltip, message, Input, Radio } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styled from "styled-components";
import imgACT2 from 'assets/images/AC_Type2.png'
import imgCCST2 from 'assets/images/CCS_Type2.png'
function EditTax() {
  const [direction] = useOutletContext();
  const [inputExpireDate, setInputExpireDate] = useState("");
  const [inputExpiryDate, setInputExpiryDate] = useState("");
  const navigate = useNavigate();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
    const onExpireDateChange = (
      value,
      dateString,
    ) => {
      console.log('onExpireDateChange : Selected Time: ', value);
      console.log('onExpireDateChange : Formatted Selected Time: ', dateString);
      setInputExpireDate(dateString);
    };

    const onExpireDateOk = (value) => {
      //console.log('onExpireDateOk : Selected Time: ', value);
    };

    const onChangeExpiryDate = (value, dateString) => {
        //console.log(value, dateString);
        console.log('onChangeExpiryDate : Selected Time: ', value);
        console.log('onChangeExpiryDate : Formatted Selected Time: ', dateString);
        setInputExpiryDate(dateString);
    };
  const [value, setValue] = useState(1);
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  return <TabSlider direction={direction} >
      <Row>
        <Col><h1><Button icon={<ArrowLeftOutlined />} type="text" onClick={() => navigate(-1)}></Button></h1></Col>
        <Col style={{width:'80%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}><center><h1>Add Tax Invoice Information</h1></center></Col>
      </Row>
    <Container>
          <Row style={{ width: '100%',  alignItems: 'center', justifyContent: 'center',alignContent: 'center',  padding:'10px'}}>
                <Card style={{ width: '100%'}}>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                          <Flex vertical gap="middle">
                            <Radio.Group defaultValue="a" buttonStyle="solid">
                              <Radio.Button value="a">Individual</Radio.Button>
                              <Radio.Button value="b">Juristic</Radio.Button>
                            </Radio.Group>
                          </Flex>
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Firstname*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Firstname" />
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Lastname*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Lastname" />
                        </Col>
                    </Row>
                    <p></p>
                   <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Tax ID*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Tax ID" />
                        </Col>
                    </Row>
                    <p></p>
                   <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           Address*
                        </Col>
                    </Row>
                    <Row style={{ alignItems: 'center'}}>
                        <Col style={{ width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center' }}>
                           <Input placeholder="Address" />
                        </Col>
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                           Province*
                    </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <Select defaultValue="Bangkok" style={{ width: '100%', }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'Bangkok',
                                          label: 'Bangkok',
                                        },
                                        {
                                          value: 'Nonthaburi',
                                          label: 'Nonthaburi',
                                        },
                                        {
                                          value: 'Samutprakarn',
                                          label: 'Samutprakarn',
                                        },
                                      ]}
                                />
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                           District*
                    </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <Select defaultValue="District" style={{ width: '100%', }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'Bangkok',
                                          label: 'Bangkok',
                                        },
                                        {
                                          value: 'Nonthaburi',
                                          label: 'Nonthaburi',
                                        },
                                        {
                                          value: 'Samutprakarn',
                                          label: 'Samutprakarn',
                                        },
                                      ]}
                                />
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                           Sub-District*
                    </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <Select defaultValue="Sub-District" style={{ width: '100%', }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'Bangkok',
                                          label: 'Bangkok',
                                        },
                                        {
                                          value: 'Nonthaburi',
                                          label: 'Nonthaburi',
                                        },
                                        {
                                          value: 'Samutprakarn',
                                          label: 'Samutprakarn',
                                        },
                                      ]}
                                />
                    </Row>
                    <p></p>
                    <Row style={{ alignItems: 'center'}}>
                           Zipcode*
                    </Row>
                           <Row style={{ alignItems: 'center'}}>
                                <Select defaultValue="Zipcode" style={{ width: '100%', }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'Bangkok',
                                          label: 'Bangkok',
                                        },
                                        {
                                          value: 'Nonthaburi',
                                          label: 'Nonthaburi',
                                        },
                                        {
                                          value: 'Samutprakarn',
                                          label: 'Samutprakarn',
                                        },
                                      ]}
                                />
                    </Row>
                    <p></p>

                </Card>
          </Row>
          <p></p>
          <Row style={{width: '100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Button type="primary" style={{fontSize: 24, width: 250, height:50}} onClick={() => navigate(-1)}>
                  <b>Save</b>
              </Button>
          </Row>
          <Row style={{ alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                <p></p>
          </Row>
    </Container>
    </TabSlider>
}
const Container = styled.div`
    display: flex;
    border-radius: 24px;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;

`;

export default EditTax;