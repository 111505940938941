import React, {useEffect, useState} from 'react';
import {useOutletContext} from "react-router-dom";
import TabSlider from "utils/TabSlider";
import styled from "styled-components";
import { Row, Col, Badge, Space } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined, CreditCardOutlined, SearchOutlined, EnvironmentOutlined } from '@ant-design/icons';
import imgCar from 'assets/images/car.png'
import axios from "axios";

function Home(){
  const [direction] = useOutletContext();
  const [user, setUser] = useState({});
  useEffect(() => {
        console.log("user : Enter useEffect : init user");
        onSearchLoginUser();
    }, []);
  const onSearchLoginUser = async () => {
      console.log("User : Enter onSearchLoginUser");

      try {
            //const response = await axios.get("/api/v1/mobile-app/user-login-info");
           // axios.get("/api/v1/user/users").then((response) => {
             axios.get("/api/v1/mobile-app/user-login-info").then((response) => {
             console.log(response.data);
             setUser(response.data);
          });
      } catch (e) {
           console.log(e);
      }
  };
    return(
        <TabSlider direction={direction} >
          <Container>
            <h2 style={{padding:'5px'}}><UserOutlined /> Welcome, {user.firstName} {user.lastName} </h2>
            <div style={{ display:'center', width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Row style={{ alignItems: 'center', padding:'5px'}}>
              <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                    <Card style={{ width: '100%' }}>
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'16'}>
                                <p style={{fontSize: 20}}>
                                <img alt="example" width="80%" src={imgCar}/></p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, padding:'2px'}}>Your Car</p>
                                <p style={{fontSize: 20, padding:'2px'}}>Volvo C40</p>

                            </Col>
                        </Row>
                    </Card>
              </Col>
              </Row>
              <Row>
                <Col style={{fontSize: 15, padding:'5px'}}>
                <h3><SearchOutlined /> Station</h3>
                </Col>
              </Row>
<Row style={{ alignItems: 'center', padding:'5px'}}>
              <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                    <Card style={{ width: '100%' }}>
                        <Row style={{ alignItems: 'center'}}>
                            <Col>
                                <p>

                                </p>
                            </Col>
                            <Col span={'18'}>
                                <p>
                                <h3>Find Station</h3>
                                </p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, padding:'2px'}}><div><Badge style={{width:70, fontSize: 15, height:25, padding:'2px', vh:'30px'}}  count={"Find"} showZero color="#faad14" /></div></p>

                            </Col>
                        </Row>
                    </Card>
              </Col>
              </Row>
              <Row>
                <Col style={{fontSize: 15, padding:'5px'}}>
                <h3><CreditCardOutlined /> Payment Method</h3>
                </Col>
              </Row>
              <Row style={{ alignItems: 'center', padding:'5px'}}>
              <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                    <Card style={{ width: '100%' }}>
                        <Row style={{ alignItems: 'center'}}>
                            <Col>
                                <p>

                                </p>
                            </Col>
                            <Col span={'18'}>
                                <p>
                                <h4>No. **** **** **** 1234</h4>
                                expire date:
                                </p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, padding:'2px'}}><Badge style={{width:70, fontSize: 15, height:25, padding:'2px', vh:'30px'}} count={"Default"} showZero color="#faad14" /></p>

                            </Col>
                        </Row>
                    </Card>
              </Col>
              </Row>
            </div>
          </Container>
        </TabSlider>
    )
}
const Container = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`;
export default Home;