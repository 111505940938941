import {createContext, useContext, useEffect, useState} from "react";
import {useAuthContext} from "./AuthContext";

const ActiveChargingContext = createContext({charging:false});


const ActiveChargingProvider = ({children}) => {
  const [charging, setCharging] = useState()
  const [status, setChargingStatus] = useState()
  const { user } = useAuthContext();

  useEffect(() => {
    if(user){

    }else{
      setCharging(false)
    }
    return () => {
      setCharging(false)
    }
  }, [user]);


  return (
    <ActiveChargingContext.Provider
      value={{ charging, status }}>
      {children}
    </ActiveChargingContext.Provider>
  )
}

export default ActiveChargingProvider;

export const useActiveChargingContext = ()=>useContext(ActiveChargingContext);