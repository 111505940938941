import QrReader from "utils/qr-reader";
import styled from "styled-components";
import {Typography} from "antd";
import {useState} from "react";
import {QrcodeOutlined} from "@ant-design/icons";
import {useOutletContext} from "react-router-dom";
import TabSlider from "utils/TabSlider";
import {useChargingContext} from "../../../contexts/ChargingContext";

function Scan() {
  const [active, setActive] = useState(false);
  const [direction] = useOutletContext();
  const {setQrCode} = useChargingContext();

  const handleQrResult = async (result,_,control) => {
        if(result&&result.text){
            setActive(false);
            const isContinue = await setQrCode(result.text);
            if(!isContinue) {
              control?.stop();
            }
        }
  }
  const handleError = (err) => {
    console.log("Error QR Scan");
  }

  return  (
    <TabSlider direction={direction}>
      <Container >
      <QrReaderContainer onError={handleError}>
        { !active&&<div style={{position:"relative",height:'100%'}}>
          <QrIconStyled onClick={()=>setActive(true)}/>
        </div>}
        {active&&<QrReaderStyled
          onError={handleError}
          onResult={handleQrResult}
          constraints={{facingMode: 'environment',width:729,height:729}}
        /> }
      </QrReaderContainer>
      <ScanLabel>
        <Typography.Title style={{marginTop:0}} level={5}>Tab QR Code Icon to start Scan</Typography.Title>
        <Typography.Text>⚡ Quick charge ahead! Scan the EV charger QR code for instant power and eco-friendly journeys. 🚗🔌</Typography.Text>
        <Typography.Text style={{color:'#0000EE'}}>#ChargeSmart #GoGreen</Typography.Text>
      </ScanLabel>
      </Container>
    </TabSlider>
  );
}
const Container = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: center;
    height: 100%;
`;
const QrReaderContainer = styled.div`
    width: min(80vw,60vh);
    height: min(80vw,60vh);
    margin-top: 16px;
    border-radius: 22px;
    overflow: hidden;
    transform: translateZ(0);
    box-shadow: 0 19px 51px 0 #00000033, 0 14px 19px 0 #00000033;
    color: #666;
    text-align: center;
    flex-shrink: 0;
`;
const QrIconStyled = styled(QrcodeOutlined)`
    font-size: min(70vw,50vh);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const QrReaderStyled = styled(QrReader)`
    & > div {                                                                                                                                        
        box-shadow: 0 19px 51px 0 #00000033, 0 14px 19px 0 #00000033;
    }
`
const ScanLabel = styled.div`
    height: 100%;
    flex-grow: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
`
export default Scan;