import {motion} from "framer-motion";
function TabSlider(props) {
  const {direction, children} = props;


  return <motion.div
    style={{height: '100%', width: '100dvw'}}
    initial={{x: 100 * direction + '%'}}
    animate={{x: 0}}
    exit={{x: window.innerWidth}}
  >
    {children}
  </motion.div>;

}

export default TabSlider;