import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {App, Button} from "antd";
import {PoweroffOutlined} from "@ant-design/icons";
import {useAuthContext} from "contexts/AuthContext";

function AppLayout(){
  const { modal} = App.useApp();
  const {logout} = useAuthContext();

  const handleLogout = () => {
    modal.confirm({
      title: "Logout",
      content: "Are you sure you want to logout?",
      transitionName: "ant-modal-slide-up",
      onOk: () => {
        logout();
      }
    })
  }

return <Container>
  <TitleBar >
    <HelloUser>
      <LogoImg alt="App" src={process.env.PUBLIC_URL + '/logo512.png'}         />
      {/*<Avatar size="large" src={pictureUrl} />*/}
      {/*<Typography.Title level={5} ellipsis style={{margin: '0 0 0 6px'}}>Hello, {displayName}</Typography.Title>*/}
    </HelloUser>
    <Button onClick={handleLogout} type="primary" shape="circle" size="large" icon={<PoweroffOutlined />} />
  </TitleBar>
  <Outlet />
</Container>
}

const Container = styled.div`
    height: 100dvh;
    width: 100dvw;
    overflow: hidden;
    background-color: ${(props) => props.theme.antd.colorPrimary};
`;
const LogoImg = styled.img`
    height: 72px;
    width: 128px;
    object-fit: cover;
`;
const TitleBar = styled.div`
    height: 72px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    z-index: 1000;
`;
const HelloUser = styled.div`
    display: flex;
    align-items: center;
`;
export default AppLayout;