import TabSlider from "utils/TabSlider";
import {useOutletContext, Link} from "react-router-dom";
import { Row, Col, Badge, Space } from "antd";
import { Avatar, Card, Tooltip, message } from 'antd';
import { AimOutlined,ApiOutlined, UserOutlined, RightCircleOutlined, DollarOutlined, FileTextOutlined, CarOutlined, SecurityScanOutlined, MailOutlined } from '@ant-design/icons';
import styled from "styled-components";

function Setting() {
  const [direction] = useOutletContext();
  return <TabSlider direction={direction} >
    <Container>
      <div style={{ display:'center', width:'100%', alignContent:'center'}}><center><h1>Setting Information</h1></center></div>
            <div style={{ display:'center', width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
              <Row style={{ alignItems: 'center', padding:'5px'}}>
              <Col style={{ width:'100%', alignItems: 'center', justifyContent: 'center',alignContent: 'center'}}>
                    <Card style={{ width: '100%' }}>
                        <Link to="/dashboard/setting/profile">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><UserOutlined/> Personal</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px'}}> <RightCircleOutlined/></p>
                            </Col>
                        </Row>
                        </Link>
                        <Link to="/dashboard/setting/car">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><CarOutlined /> Vehicle</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px'}}> <RightCircleOutlined /></p>
                            </Col>
                        </Row>
                        </Link>
                        <Link to="/dashboard/setting/tax">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><FileTextOutlined /> Tax</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px'}}> <RightCircleOutlined /></p>
                            </Col>
                        </Row>
                        </Link>
                        <Link to="/dashboard/setting/price">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><DollarOutlined /> Price Rate</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px'}}> <RightCircleOutlined /></p>
                            </Col>
                        </Row>
                        </Link>
                        <Link to="/dashboard/setting/privacy">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><SecurityScanOutlined /> Privacy Policy</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px' }}> <RightCircleOutlined /></p>
                            </Col>
                        </Row>
                        </Link>
                        <Link to="/dashboard/setting/contact">
                        <Row style={{ alignItems: 'center'}}>
                            <Col span={'22'}>
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px', color:"#000000" }}><MailOutlined /> Contact Us</p>
                            </Col>
                            <Col >
                                <p style={{fontSize: 20, height:25, padding:'2px', vh:'30px'}}> <RightCircleOutlined /></p>
                            </Col>
                        </Row>
                        </Link>
                    </Card>
              </Col>
              </Row>
            </div>
    </Container>
  </TabSlider>
}

const Container = styled.div`
    display: flex;    
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
`;
export default Setting;